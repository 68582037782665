import { FC, useState } from 'react'
import { GroupsData, UserData } from '../../data/types'
import './userManagement.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRemove } from '@fortawesome/free-solid-svg-icons'

import { useNetworkManager } from '../../network/networkManager'

interface UserManagerProps {
    groups: GroupsData[]
    users: UserData[]
}

const UserManager: FC<UserManagerProps> = ({ groups, users }) => {
    const [newGroupName, setNewGroupName] = useState<string>('')

    const { groups: groupsAPI } = useNetworkManager()

    // State to track selected group for each user when adding them to a new group
    const [selectedGroupsForUsers, setSelectedGroupsForUsers] = useState<{
        [userId: string]: string
    }>({})

    // Function to create a new group
    const handleCreateGroup = async () => {
        await groupsAPI.addGroup(newGroupName)
    }

    // Function to add a user to a group
    const handleAddGroupToUser = async (userId: string) => {
        const groupId = selectedGroupsForUsers[userId]
        if (groupId) {
            // Find the group by groupId
            const group = groups.find((group) => group.id === groupId)

            // Check if the group exists and if the user is NOT in the group's users list
            if (group && !group.users?.some((user) => user.id === userId)) {
                await groupsAPI.addUser(userId, groupId)
            } else {
                console.log(`User ${userId} is already in group ${groupId}`)
            }
            // Reset the selected group for this user
            setSelectedGroupsForUsers({
                ...selectedGroupsForUsers,
                [userId]: '',
            })
        }
    }

    // Function to remove a user from a group
    const handleRemoveUserFromGroup = async (userId: string, groupId: string) => {
        // Find the group by groupId
        const group = groups.find((group) => group.id === groupId)

        // Check if the group exists and if the user is in the group's users list
        if (group && group.users?.some((user) => user.id === userId)) {
            await groupsAPI.removeUser(userId, groupId)
        } else {
            console.log(`User ${userId} is not in group ${groupId}`)
        }
    }

    const sortedUsers = [...users].sort((a, b) => {
        const nameA = `${a.given_name} ${a.family_name}`.toLowerCase()
        const nameB = `${b.given_name} ${b.family_name}`.toLowerCase()
        return nameA.localeCompare(nameB)
    })

    const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name))

    return (
        <div className="field-recorder-wrapper">
            <div className="field-recorder">
                <div className="user-management">
                    <div className="user-list">
                        {sortedUsers.map((user) => (
                            <div key={user.id} className="user">
                                <div>
                                    <div className="user-name">
                                        {user.given_name} {user.family_name}
                                    </div>
                                    <div style={{ marginLeft: '5px' }}>
                                        <div className="user-roles">
                                            <div className="title">Roles</div>
                                            <div className="roles">
                                                {user.roles.map((role, i) => (
                                                    <div key={i}>{role}</div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="user-groups">
                                            <div className="title">Groups</div>
                                            <div className="groups">
                                                {sortedGroups
                                                    ?.filter((group) =>
                                                        group.users?.some((u) => u.id === user.id)
                                                    )
                                                    .map((group) => (
                                                        <div key={group.id} className="group-entry">
                                                            <div className="group-item">
                                                                {group.name}
                                                            </div>
                                                            <span className="delete-entry">
                                                                <FontAwesomeIcon
                                                                    icon={faRemove}
                                                                    onClick={() =>
                                                                        handleRemoveUserFromGroup(
                                                                            user.id,
                                                                            group.id
                                                                        )
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="add-group-to-user">
                                    {/* todo: only show groups the user is not already in. Reference above code for filtering */}
                                    <select
                                        value={selectedGroupsForUsers[user.id] || ''}
                                        onChange={(e) =>
                                            setSelectedGroupsForUsers({
                                                ...selectedGroupsForUsers,
                                                [user.id]: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="">Select Group</option>
                                        {sortedGroups
                                            .filter(
                                                (group) =>
                                                    !group.users?.some(
                                                        (_user) => _user.id === user.id
                                                    )
                                            )
                                            .map((group) => (
                                                <option key={group.id} value={group.id}>
                                                    {group.name}
                                                </option>
                                            ))}
                                    </select>
                                    <button
                                        onClick={() => handleAddGroupToUser(user.id)}
                                        disabled={!selectedGroupsForUsers[user.id]}
                                    >
                                        Add to Group
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="modify-groups">
                        <div className="new-group">
                            <div className="title">New Group:</div>
                            <div className="input">
                                <input
                                    value={newGroupName}
                                    onChange={(e) => setNewGroupName(e.target.value)}
                                />
                                <button onClick={handleCreateGroup}>Create Group</button>
                            </div>
                        </div>
                        <div className="existing-groups">
                            <div className="title">Existing Groups:</div>
                            <div className="groups">
                                {sortedGroups.map((group) => (
                                    <span>{group.name}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManager
