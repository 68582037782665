import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, GroupsData, ErrorPayload, UserData } from '../types'

const initialState: DataState<GroupsData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

// This function sorts all string arrays within objects recursively.
const recursivelySortObject = (groupsData: GroupsData[]) => {
    const sortArraysInObject = (obj: any): any => {
        if (Array.isArray(obj)) {
            if (obj.every((item) => typeof item === 'string')) {
                // Sort the array if it contains only strings
                return [...obj].sort((a, b) =>
                    a.localeCompare(b, undefined, { sensitivity: 'base' })
                )
            } else {
                // Recursively process each item in the array
                return obj.map(sortArraysInObject)
            }
        } else if (obj && typeof obj === 'object') {
            // Recursively process each key in the object
            const sortedObj: any = {}
            for (const key in obj) {
                sortedObj[key] = sortArraysInObject(obj[key])
            }
            return sortedObj
        } else {
            // Return the value if it's neither an array nor an object
            return obj
        }
    }

    return groupsData.map((group) => sortArraysInObject(group))
}

interface AddUserPayload {
    groupId: string
    user: UserData
}

interface RemoveUserPayload {
    groupId: string
    userId: string
}

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },
        fetchGroupsSuccess(state, action: PayloadAction<GroupsData[]>) {
            state.loading = false
            state.data = recursivelySortObject(action.payload)
            state.error.message = ''
        },
        addGroup(state, action: PayloadAction<GroupsData>) {
            state.loading = false
            state.data.push(action.payload)
            state.data = recursivelySortObject(state.data)
            state.error.message = ''
        },
        addUser(state, action: PayloadAction<AddUserPayload>) {
            const { groupId, user } = action.payload
            const groupIndex = state.data.findIndex((g) => g.id === groupId)
            if (groupIndex !== -1) {
                const group = state.data[groupIndex]
                if (!group.users) {
                    group.users = []
                }
                group.users.push(user)
                state.data = recursivelySortObject(state.data)
                state.error.message = ''
                state.loading = false
            }
        },
        removeUser(state, action: PayloadAction<RemoveUserPayload>) {
            const { groupId, userId } = action.payload
            const groupIndex = state.data.findIndex((g) => g.id === groupId)
            if (groupIndex !== -1) {
                const group = state.data[groupIndex]
                if (group.users) {
                    group.users = group.users.filter((u) => u.id !== userId)
                }
                state.data = recursivelySortObject(state.data)
                state.error.message = ''
                state.loading = false
            }
        },
    },
})

export const {
    fetchDataRequest,
    fetchDataFailure,
    fetchGroupsSuccess,
    addGroup,
    addUser,
    removeUser,
} = groupsSlice.actions

export default groupsSlice.reducer
